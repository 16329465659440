/* ----------------------- HeroesNeverDie */

.hnd__FlexRow {
  display: flex;
  flex-direction: row;
}

.hnd__VerticalAlignBottom {
  /* background-color: orange; */
  /* height: inherit; */
  /* display: flex;
  flex-direction: column;
  justify-content: flex-end; */
  /* position: relative; */
  /* bottom: 0; */
}

/* ---------------------- HeroesNeverDie */

.hnd_body {
  color: white;
}

.hnd_container {
  display: flex;
  flex-direction: row;

  display: grid;
  grid-template-columns: repeat(auto-fill, 10%);
  gap: 10px; /* Adjust the gap between grid items as needed */
}

.hnd_item {
  /* background-color: #f0f0f0; */
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;
}

.hnd_gradient {
  background: linear-gradient(to right, #bdff06, #58a500);
  background-clip: text;
  color: transparent;
  font-size: 1.3rem;
  font-weight: bold;
}
