.FlexRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  /* align-items: center; */
  justify-content: center;
}
.FlexRow_Pure {
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */

  /* align-items: center; */
  /* justify-content: center; */
}

.FlexColumn {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  align-items: center;
  justify-content: center;
}

.glo_grid_3x3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  /* gap: 1px; */
}

.glo_width50px {
  width: 50px;
}
.glo_width100px {
  width: 100px;
}
.glo_width150px {
  width: 150px;
}
.glo_width200px {
  width: 200px;
}
.glo_width300px {
  width: 300px;
}
.glo_width1000px {
  width: 1000px;
}

.glo_shiftleft:hover {
  /* background-color: #fff; */
  margin-left: 1rem;
  /* font-size: 1.05rem; */
}

.glo_Btn_Normal {
  background-color: rgba(0, 255, 0, 0.1);
  color: white;
  padding: 1rem;
  border-radius: 0.5rem;
}

.glo_Btn_Normal:hover {
  cursor: pointer;
  background-color: rgb(162, 255, 0);
  color: black;
}
