.cps_FlexRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  /* align-items: center; */
  justify-content: center;
}

.cps_FlexColumn {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  align-items: center;
  justify-content: center;
}

.cps_glo_grid_3x3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  /* gap: 1px; */
}

.cps_glo_width100px {
  width: 100px;
}
.cps_glo_width150px {
  width: 150px;
}
.cps_glo_width200px {
  width: 200px;
}
.cps_glo_width300px {
  width: 300px;
}

.cps_glo_shiftleft:hover {
  /* background-color: #fff; */
  margin-left: 1rem;
  /* font-size: 1.05rem; */
}

.cps_glo_Btn_Normal {
  background-color: rgba(0, 255, 0, 0.1);
  color: white;
  padding: 1rem;
  border-radius: 0.5rem;
}

.cps_glo_Btn_Normal:hover {
  cursor: pointer;
  background-color: rgb(162, 255, 0);
  color: black;
}

.cps_table_pcstatus {
  background-color: rgba(0, 0, 0, 0);
  color: white;
}

.cps_table_pcstatus tbody tr:hover {
  /* background-color: rgba(7, 85, 82, 0.5); */
  /* cursor: pointer; */
}

.cps_table_pcstatus thead tr th {
  background-color: #014f46;
  color: white;
}
