.ContainerFilter {
  position: sticky;
  top: 0;
  z-index: 999;

  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(40px);
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.5);
}

.Btn_Filter {
  margin: 0.5rem;
  background: rgb(54, 54, 54);
  padding-top: 1.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 0rem;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 20px;
}
.Btn_Filter:hover {
  background: rgb(130, 129, 129);
}

.TB_Filter {
  margin: 0.2rem;
}

.Table_TAG {
  min-width: 1024px;
}

.Table_Click {
  text-align: left;
  color: white;
}

.Table_Click:hover {
  color: greenyellow;
}
.Table_Row_Hightlight {
  cursor: pointer;
}

.Table_Row_Hightlight:hover {
  background-color: rgb(49, 42, 0);
}

/* //--------------------------------- */
.nav1_theme {
  background: linear-gradient(45deg, #344955 30%, #50727b 90%);
  color: #b3dcbf;
}
.nav2_theme {
  background: linear-gradient(45deg, #202d35 30%, #25363a 90%);
  color: #b3dcbf;
}

.nivo-calendar {
  color: black;
}

.table_pcstatus {
  background-color: rgba(0, 0, 0, 0);
  color: white;
}

.table_pcstatus tbody tr:hover {
  /* background-color: rgba(7, 85, 82, 0.5); */
  /* cursor: pointer; */
}

.table_pcstatus thead tr th {
  background-color: #014f46;
  color: white;
}

.Chart_Calender1 {
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */

  /* align-items: center; */
  /* justify-content: center; */

  width: 100%;
  /* background-color: cyan; */
}
