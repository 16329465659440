.PCHardware_CP_Container {
  background-color: rgba(7, 85, 82, 0.2);

  color: white;
  border-radius: 1rem;
  padding: 1rem;
}

.PCHardware_Container {
}

.PCHardware_Container_Cafe {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}
.PCHardware_Container_Cafe_sub {
  background-color: rgba(7, 85, 82, 0.5);
  color: white;
  display: grid;
  grid-template-columns: 0.5fr 3fr 1fr;
  justify-content: center;
  font-size: 15px;

  padding-left: 5px;
  padding-right: 5px;
}

.PCHardware_Container_Cafe_sub:hover {
  background-color: rgba(7, 85, 82, 0.8);
  color: white;

  cursor: pointer;
}

.PCHardware_Container_Cafe_sub :nth-child(1) {
  color: gray;
  text-align: right;
  padding-right: 5px;
  font-size: 10px;
}

.PCHardware_Container_Cafe_sub :nth-child(2) {
  /* background-color: rgb(7, 85, 82);
  color: white; */
  text-align: left;
}

.PCHardware_Container_Cafe_sub :nth-child(3) {
  /* background-color: #fff; */
  color: rgb(107, 254, 249);
  text-align: right;
}

.PCHardware_Container_2 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 5px;
}

.PCHardware_Container_2 div {
  text-align: center;
}

.PCHardware_Container_2_title {
  color: white;
}

.PCHardware_Container_3alpha {
  background-color: rgba(7, 85, 82, 0.2);
  margin-bottom: 20px;
  border-radius: 1rem;
}

.PCHardware_Container_3 {
  background-color: rgba(7, 85, 82, 0.5);
  color: white;
  display: grid;
  grid-template-columns: 0.5fr 3fr 1fr;
  justify-content: center;
  font-size: 12px;

  padding-left: 5px;
  padding-right: 5px;
}

.PCHardware_Container_3:hover {
  background-color: rgba(7, 85, 82, 0.8);
  color: white;

  cursor: pointer;
}

.PCHardware_Container_3 :nth-child(1) {
  color: gray;
  text-align: right;
  padding-right: 5px;
  font-size: 10px;
}

.PCHardware_Container_3 :nth-child(2) {
  /* background-color: rgb(7, 85, 82);
  color: white; */
  text-align: left;
}

.PCHardware_Container_3 :nth-child(3) {
  /* background-color: #fff; */
  color: rgb(107, 254, 249);
  text-align: right;
}

.PCHardware_Container_3sub_b4 :hover {
  background-color: rgb(61, 52, 1);
  color: black;
  cursor: pointer;
}

.PCHardware_Container_3sub {
  width: 85%;

  background-color: rgba(0, 0, 0, 0.5);

  display: grid;
  grid-template-columns: 0.5fr 3fr 1fr;
  justify-content: right;
  font-size: 12px;

  padding-left: 25px;
  padding-right: 5px;
}

.PCHardware_Container_3sub :nth-child(1) {
  color: gray;
}
.PCHardware_Container_3sub :nth-child(2) {
  color: rgb(215, 244, 176);
  text-align: left;
}
.PCHardware_Container_3sub :nth-child(3) {
  color: rgb(162, 255, 0);
}

.PCHardware_Container_3sub2 {
  width: 75%;
  background-color: rgba(0, 0, 0, 0.5);

  display: grid;
  grid-template-columns: 0.5fr 1fr 1fr;
  justify-content: right;
  font-size: 12px;

  padding-left: 25px;
  padding-right: 5px;
}

.PCHardware_Container_3sub2 :nth-child(1) {
  color: gray;
}
.PCHardware_Container_3sub2 :nth-child(2) {
  color: rgb(218, 187, 164);
  text-align: left;
}
.PCHardware_Container_3sub2 :nth-child(3) {
  color: rgb(244, 155, 40);
}

.PCHardware_Container__cafelist {
  font-size: 10px;
  color: gray !important;
}
.PCHardware_Container__cafelist:hover {
  /* background-color: rgb(28, 78, 23); */
  color: lime !important;
  cursor: pointer;
}

.PCHardware_Container__pclist {
  font-size: 10px;
  color: gray !important;
}
.PCHardware_Container__pclist:hover {
  /* background-color: rgb(28, 78, 23); */
  color: rgb(255, 149, 0) !important;
}
